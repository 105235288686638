<template>
  <a-tree-select
    v-bind="record.options"
    :value="selectedValue"
    :getPopupContainer="record.options.getPopupContainer || (triggerNode => triggerNode.parentNode)"
    @change="change"
    @search="search"
    @select="select"
    @treeExpand="treeExpand"
  />
</template>

<script>
import formEleMixin from './form-ele-mixin'
export default {
  name: 'TreeSelect',
  mixins: [formEleMixin],
  computed: {
    selectedValue () {
      const { multiple, labelInValue, treeData = [] } = this.record.options
      const { value, valueKey, labelKey } = this
      if (this.isEmpty(value)) return
      if (labelInValue && this.getDataType(value) !== 'Object') {
        const valueArr = this.formatValue(value)
        if (this.getDataType(valueArr[0]) === 'Object') {
          return value.map(item => {
            return { label: item[labelKey], value: item[valueKey] }
          })
        }
        const realValue = []
        const findLabel = (treeData) => {
          valueArr.forEach(val => {
            const label = treeData.find(option => option[valueKey] === val)?.[labelKey]
            if (label) realValue.push({ label: label, value: val })
            else if (treeData.children?.length > 0) findLabel(treeData.children)
          })
        }
        findLabel(treeData, value)
        return multiple ? realValue : realValue[0]
      }
      return multiple ? this.formatValue(value) : value
    }
  },
  methods: {
    change (selectedValue, label, extra) {
      if (Array.isArray(selectedValue)) {
        const { labelInValue, isValueArray } = this.record.options
        const value = labelInValue || isValueArray ? selectedValue : this.formatValue(selectedValue, 'toStr')
        this.$emit('change', value, this.record.model)
      } else {
        this.$emit('change', selectedValue, this.record.model, label, extra)
      }
    },
    search (value) {
      this.$emit('search', value, this.record.model)
    },
    select (value, node, extra) {
      this.$emit('select', value, this.record.model, node, extra)
    },
    treeExpand (expandedKeys) {
      this.$emit('treeExpand', expandedKeys, this.record.model)
    }
  }
}
</script>
